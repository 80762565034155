<template>
  <div>
    <el-row :gutter="20" style="margin-bottom: 20px;">
      <el-col :span="20">
        <el-select v-model="queryInfo.helpType" placeholder="协助类型" class="input-short" filterable clearable>
          <el-option v-for="item in helpTypeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
        <el-input clearable maxlength="64" v-model="queryInfo.orderNo" placeholder="单号" class="input-short"></el-input>
        <el-input clearable maxlength="64" v-model="queryInfo.processId" placeholder="协助单ID" class="input-short"></el-input>
        <el-button type="primary" style="margin-left: 10px" @click="currentChange(1)" :loading="loading">查询</el-button>
      </el-col>
    </el-row>

    <ProcessTableComponent
        :processList="processList"
        :pageSize="pageSize"
        :pageNum="queryInfo.pageNo"
        :total="total"
        @pageChange="currentChange"
        @selectChange="handleCurrentChange"
    ></ProcessTableComponent>

    <el-row style="margin-top: 30px">
      <el-form :model="formData" label-width="150px">

        <template v-for="item in inputItems">
          <el-form-item
              v-if="checkShowSettings(item.showSettingsMap, item.field)"
              :label="item.name+'：'"
              :key="item.field"
              :required="checkRequireSettings(item.requireSettingsMap)"
          >
            <el-input v-if=" item.type=='string'" v-model="formData[item.field]" class="input-middle" type="textarea" :autosize="true" clearable maxlength="200"></el-input>
            <el-select v-if="item.type=='dict'" v-model="formData[item.field]" class="input-middle">
              <el-option v-for="dict in item.dictValueList" :label="dict.label" :value="dict.value" :key="dict.value"></el-option>
            </el-select>
          </el-form-item>
        </template>

        <el-form-item label="图片：">
          <ImageUploadComponent url="upload/helpFile" ref="uploadComponent" :img-cnt="3" @imageChange="setImgIds"></ImageUploadComponent>
        </el-form-item>
        <el-form-item label="Excel文件：">
          <DocUploadComponent url="upload/helpFile" ref="excelUpload" accept=".xls,.xlsx" @docChange="setExcelIds" style="width: 450px"/>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="submit">提交</el-button>
          <MessageComponent ref="commitMessage"/>
        </el-form-item>
      </el-form>
    </el-row>

  </div>
</template>

<script>
import ProcessTableComponent from "@/views/process/ProcessTableComponent";
import MessageComponent from "@/components/MessageComponent";
import DocUploadComponent from "@/components/DocUploadComponent";
import ImageUploadComponent from "@/components/ImageUploadComponent";

export default {
  name: "MyHelpTask",
  components: {ImageUploadComponent, DocUploadComponent, MessageComponent, ProcessTableComponent},
  data() {
    return {
      queryInfo: {
        helpType: '',
        orderNo: '',
        processId: '',
        finish: false,
        pageNo: 1
      },
      formData: {},
      processList: [],
      loading: false,
      pageSize: 0,
      total: 0,
      inputItems: []
    }
  },
  computed: {
    helpTypeList() {
      return this.$store.getters.getDictList('helpType') || [];
    }
  },
  created() {
    this.currentChange(1)
  },
  methods: {
    checkShowSettings(settingsObject) {
      if (settingsObject == null) {
        return true;
      }

      if (settingsObject.condition == 'always') {
        return true;
      }

      if (settingsObject.condition == 'none') {
        return false;
      }

      if (settingsObject.condition == 'field') {

        if (settingsObject.field == null || settingsObject.field.length == 0) {
          return false;
        }

        if (settingsObject.value == null || settingsObject.value.length == 0) {
          return false;
        }

        if (settingsObject.match_type == null || settingsObject.match_type.length == 0) {
          return false;
        }

        //判断field的值是否一致
        if (settingsObject.match_type == 'eq' && this.formData[settingsObject.field] == settingsObject.value) {
          return true;
          //简单处理了，不严密
        } else if (settingsObject.match_type == 'in' && this.formData[settingsObject.field] != null && settingsObject.value.indexOf(this.formData[settingsObject.field]) > 0) {
          return true;
        } else {
          //需要清空之前可能输入过的值
          //if(inputField != null && inputField.length > 0){
          //    this.formData[inputField] = ''
          //}
        }

        return false;
      }

      return false;
    },
    checkRequireSettings(settingsObject) {
      if (settingsObject == null) {
        return true;
      }

      if (settingsObject.condition == 'always') {
        return true;
      }

      if (settingsObject.condition == 'none') {
        return false;
      }

      if (settingsObject.condition == 'field') {

        if (settingsObject.field == null || settingsObject.field.length == 0) {
          return false;
        }

        if (settingsObject.value == null || settingsObject.value.length == 0) {
          return false;
        }

        if (settingsObject.match_type == null || settingsObject.match_type.length == 0) {
          return false;
        }

        //判断field的值是否一致
        if (settingsObject.match_type == 'eq' && this.formData[settingsObject.field] == settingsObject.value) {
          return true;
        }

        return false;
      }

      return false;
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    search() {
      this.resetValues()
      this.loading = true;
      this.$axios.post('help/queryMyHelpTask', this.queryInfo).then(response => {
        this.loading = false;
        let res = response.data
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        this.processList = res.data.records;
        this.total = res.data.total;
        this.pageSize = res.data.size;
      }, error => {
        this.loading = false;
        return this.$message.error(`操作失败，${error.message}`);
      })
    },
    submit() {
      if (!this.currentRow || !this.currentRow.id) {
        return this.$refs.commitMessage.showError("请先选择一个任务")
      }

      this.loading = true;
      this.$axios.post('process/submitTask', this.formData).then(response => {
        this.loading = false;
        let res = response.data
        if (res.code !== 0) {
          return this.$refs.commitMessage.showError(res.message);
        }
        this.currentChange(1)
        this.$refs.commitMessage.showSuccess(`提交成功`);
        this.resetValues()
        this.loadMyHelpTaskCnt()
      }, error => {
        this.loading = false;
        return this.$refs.commitMessage.showError(`操作失败，${error.message}`);
      })
    },
    handleCurrentChange(row) {
      this.resetValues()

      if (row == null) {
        return;
      }

      this.currentRow = row;
      this.inputItems = this.currentRow.nodeDef?.inputItemList || [];
      this.formData.taskId = this.currentRow.id
    },
    resetValues() {
      this.currentRow = {};
      this.inputItems = [];
      this.formData = {taskId: '', fileId: '', imageIds: [], remark: ''}
      this.$refs.uploadComponent?.onClear()
      this.$refs.excelUpload?.onClear()
    },
    setImgIds(idList) {
      this.formData.imageIds = idList
    },
    setExcelIds(excelIds) {
      if (excelIds && excelIds.length > 0) {
        this.formData.fileId = excelIds[0];
      } else {
        this.formData.fileId = '';
      }
    },
    loadMyHelpTaskCnt() {
      this.$axios.get("help/queryMyHelpTaskCnt").then(response => {
        if (response.data.code !== 0) {
          this.$message.error('获取协助任务数量失败');
        }
        this.$store.commit("setHelpTaskCnt", response.data.data);
      })
    },
  }
}
</script>

<style scoped>
.input-short {
  width: 250px;
  margin-right: 5px;
}

.input-middle {
  width: 300px;
}
</style>